import API from '@axios'

const API_URL = '/api/core'

export default {
  updateCompany(payload) {
    return API.patch(`${API_URL}/settings`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateLanguage(key, payload) {
    return API.put(`${API_URL}/settings/${key}`, payload, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  },
}
