<template>
  <div>
    <b-card class="card">
      <div class="row-flex-align-center row-flex-justify-space-between card__header">
        <span class="tw-font-bold text-white tw-text-xl">
          {{ $t('Language') }}
        </span>
      </div>
      <b-form class="mt-2">
        <b-row>
          <b-col sm="12" lg="6" xl="4">
            <b-form-group
              :label="$t('Language')"
            >
              <ProzessSelect v-model="language" :options="languageOptions" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              class="mt-2 mr-1 d-flex"
              :disabled="!hasSystemSettingsActions || sending"
              @click="updateProfile"
            >
              <b-spinner
                v-if="saving"
                class="mr-1"
                small
              />
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import { languageOptions } from '@/constants/app'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'
import { mapState } from 'vuex'
import coreService from '../../../services/core'

export default {
  components: {
    ProzessSelect,
  },

  data() {
    return {
      languageOptions,
      saving: false,
      language: '',
    }
  },

  computed: {
    ...mapState('user', [
      'user',
    ]),
  },

  watch: {
    user: {
      handler(val) {
        this.language = val.language
          ? this.languageOptions.find(lang => lang.key === val.language)
          : this.languageOptions[0]
      },
      immediate: true,
    },
  },

  methods: {
    updateProfile() {
      this.saving = true

      coreService.updateLanguage('language', this.language.key)
        .then(() => {
          this.$getUserAndPermissions()
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>
